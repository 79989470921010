import React from "react";
import { IAnswerMapping } from "../../domain/mappingTypes";
import { SelectOption } from "@avalara/skylab-react";
import { AnimatePresence, motion } from "framer-motion";
import { SkylabSelectWrapper } from "../../ui/FixSelectRequired/FixSelectRequired";

type Props = {
  id?: string;
  index?: number;
  item: IAnswerMapping;
  isOptional: boolean;
  changeSelectToInput: boolean | undefined;
  showSwitchButton: boolean;
  selectShemaOption: SelectOption[];
  inputRef: React.RefObject<HTMLInputElement>;
  inputValue: string;
  selectValue: string;
  onChangeSelect: (value: any) => void;
  onTrasnformSelectInput: (isInput: boolean, index: number | undefined) => void;
  onBlurInputColumnName: () => void;
  onChangeInputColumnName: (value: string) => void;
};

const transitionConfig = {
  duration: 0.4,
};

const flipVariants = {
  hidden: { opacity: 0, rotateX: 90 },
  visible: { opacity: 1, rotateX: 0 },
  exit: { opacity: 0, rotateX: -90 },
};

const SkylabSelectOrInputText = (props: Props) => {
  const id = props.id ?? "default";
  const index = props.index ?? 0;
  return (
    <AnimatePresence initial={false}>
      {!props.changeSelectToInput ? (
        <motion.div
          key={`input-column-name-${id}`}
          style={{ position: "absolute", width: "100%" }}
          variants={flipVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={transitionConfig}
        >
          <SkylabSelectWrapper
            name={`dropdown-column-name-${id}-${props.item.from.columnName}-${index}`}
            className={`skylab-select-container ${
              props.showSwitchButton ? "size-with-button" : ""
            }`}
            inputId={`dropdown-column-name-${id}-${props.item.from.columnName}-${index}`}
            multiple={false}
            disabled={props.isOptional}
            showSelectionCount={false}
            required={!props.isOptional}
            data-testid={`dropdown-column-name-${id}-${props.item.from.columnName}-${index}`}
            value={props.selectValue || ""}
            onSSelect={(e: any) => props.onChangeSelect(e.detail.item.value)}
            optionsList={props.selectShemaOption}
          />

          {props.showSwitchButton && (
            <div style={{ display: "inline-block" }}>
              <button
                title="Enable 'new column' mode"
                type="button"
                data-testid={`button-new-column-mode-${id}-${props.item.from.columnName}-${index}`}
                className={`button-transform-select add ${props.isOptional ? "disabled" : ""}`}
                onClick={() => props.onTrasnformSelectInput(true, props.index)}
              >
                +
              </button>
            </div>
          )}
        </motion.div>
      ) : (
        <motion.div
          key={`select-column-name-${id}`}
          style={{ position: "absolute", width: "100%" }}
          variants={flipVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={transitionConfig}
        >
          <input
            id={`input-column-name-${id}-${props.item.from.columnName}-${index}`}
            type="text"
            aria-label="new column name"
            ref={props.inputRef}
            style={{ width: "calc(100% - 40px)", display: "inline-block" }}
            data-testid={`input-column-name-${id}-${props.item.from.columnName}-${index}`}
            disabled={props.isOptional}
            required={!props.isOptional}
            value={props.inputValue || ""}
            onBlur={props.onBlurInputColumnName}
            onChange={(e) => props.onChangeInputColumnName(e.target.value)}
          />

          <div style={{ display: "inline-block" }}>
            <button
              title="Disable/exit 'new column' mode"
              type="button"
              data-testid={`button-back-column-mode-${id}-${props.item.from.columnName}-${index}`}
              className="button-transform-select cancel"
              onClick={() => props.onTrasnformSelectInput(false, props.index)}
            >
              <span className="icon-undo"></span>
            </button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SkylabSelectOrInputText;
