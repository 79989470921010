const getConfig = () => {
  if (window.vat_mapper_config)
    return {
      APP_API: window.vat_mapper_config.APP_API,
      FILES_API: window.vat_mapper_config.FILES_API,
      VAT_PLATFORM_FILES_API: window.vat_mapper_config.VAT_PLATFORM_FILES_API,
      FORWARD_DESTINY_PATH: window.vat_mapper_config.FORWARD_DESTINY_PATH,
      FORWARD_DESTINY_REGION: window.vat_mapper_config.FORWARD_DESTINY_REGION,
      FORWARD_DESTINY_TYPE: window.vat_mapper_config.FORWARD_DESTINY_TYPE,
      REQUEST_WITH_CREDENTIALS: window.vat_mapper_config.REQUEST_WITH_CREDENTIALS
    };
};

export default getConfig;
