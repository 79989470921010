import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface IFadeInEffectProps {
  children: React.ReactElement;
  showElement: boolean;
  id: string;
  duration?: number;
}

const FadeInEffect: React.FunctionComponent<IFadeInEffectProps> = (props) => {
  const duration = !props.duration ? 0.6 : props.duration;
  return (
    <AnimatePresence initial={false}>
      <motion.div
        key={props.id}
        transition={{ duration }}
        initial={{ opacity: 0 }}
        style={{ opacity: 0 }}
        animate={{
          opacity: props.showElement ? 1 : 0,
        }}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
};

export default FadeInEffect;
