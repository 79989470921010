import { Dialog } from "@avalara/skylab-react";

import React from "react";

type Props = {
  title: string;
  content: string | JSX.Element;
  showDialog: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const ConfirmDialog: React.FunctionComponent<Props> = (props) => {
  return (
    <Dialog open={props.showDialog} id="generic-dialog" onSDismiss={props.onCancel} noScroll={false}>
      <div slot="header" id="dialog-title">
        {props.title}
      </div>
      <div slot="body">{props.content}</div>
      <div slot="footer">
        <button className="secondary small" onClick={props.onCancel}>
          Cancel
        </button>
        <button className="primary small" onClick={props.onConfirm}>
          Save
        </button>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
