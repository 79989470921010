import React, { useState } from "react";

import { Alert, Stepper } from "@avalara/skylab-react";
import { useMappingStepper } from "../../../hooks/useMappingStepper";
import { MappingContext } from "../../../context/mappingDataContext";
import { addSchemaWorkflow } from "../../../services/schemaService";
import {
  convertionToCSV,
  convertionToJQ,
  convertionToTransformation,
} from "../../../tools/schemaConversionTools";
import { useNavigate } from "react-router-dom";
import FieldsMapping from "../FieldsMapping";
import Review from "../Review";
import SetCountries from "../SetCountries";
import getConfig from "../../../services/config";
import "./styles.scss";

const Wapper = () => {
  const components = [
    <SetCountries key="setCountryPage" previousStep={0} />,
    <FieldsMapping key="fieldsMappingPage" />,
    <Review key="reviewPage" />,
  ];

  const {
    dataComponent,
    fromData,
    fromTo,
    headers,
    operators,
    selectedCountries,
    selectedTypes,
    values,
    setData,
  } = MappingContext();

  const { currentStep, currentComponent, isLastStep, changeStep } = useMappingStepper({
    steps: components,
    save: () => createSchema(),
  });

  const [hasError, setHasError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Error when trying to save");
  const navigate = useNavigate();

  const reservedSchemaNames = [
    "generic",
    "genericmulti",
    "genericintrastat",
    "genericextrastat",
    "reportingdocumentcollection",
    "avatemplate",
    "avtr",
    "clearingtxt",
    "gentxt",
  ];

  const isSchemaNameInvalid = () => {
    if (reservedSchemaNames.includes(dataComponent.schemaName)) {
      return true;
    } else {
      return false;
    }
  };

  const setHeadersProperties = () => {
    let updatedHeaders = headers;
    fromTo.forEach((obj) => {
      if (obj.isDate) {
        let headerToUpdate = updatedHeaders.find((header) => header.name === obj.to);
        if (headerToUpdate) {
          headerToUpdate.isDate = obj.isDate;
        }
      }
    });
    return updatedHeaders;
  };

  const createSchema = async () => {
    setHasError(false);
    setLoader(true);

    if (isSchemaNameInvalid()) {
      setErrorMessage("The schema name specified is unavailable, please choose a different name.");
      setHasError(true);
      setLoader(false);
      return;
    }

    const updatedHeaders = setHeadersProperties();
    const csv = convertionToCSV(
      dataComponent.schemaSeparator,
      true,
      dataComponent.headerRowPosition,
      updatedHeaders,
      fromTo,
      dataComponent.dateFormat
    );
    const jq = convertionToJQ(fromTo);
    const transformationData = convertionToTransformation(fromTo);
    const snapshot = {
      dataComponent,
      fromData,
      fromTo,
      headers,
      operators,
      selectedCountries,
      selectedTypes,
      values,
    };

    const result = await addSchemaWorkflow({
      forwardDestinyPath: getConfig()?.FORWARD_DESTINY_PATH,
      forwardDestinyType: getConfig()?.FORWARD_DESTINY_TYPE,
      tenantId: dataComponent.tenantId,
      forwardDestinyRegion: getConfig()?.FORWARD_DESTINY_REGION,
      name: dataComponent.schemaName,
      extension: "csv",
      CsvSchema: csv,
      jqString: jq,
      transformations: transformationData,
      outputSchemaName: `GT - ${dataComponent.schemaName}`,
      snapshot: JSON.stringify(snapshot),
    });

    if (!result?.error) {
      setData((d) => {
        return { ...d, result };
      });
      localStorage.setItem("saveSuccess", dataComponent.schemaName);
      navigate(`/?TenantId=${dataComponent.tenantId}`);
    } else if (result?.error) {
      setErrorMessage(result.error);
      setHasError(true);
    } else {
      setErrorMessage("Error when trying to save");
      setHasError(true);
    }

    setLoader(false);
  };

  const dataToStepper = [
    {
      label: "Import settings",
      active: currentStep === 0,
      completed: currentStep > 0,
    },
    {
      label: "Required and optional columns",
      active: currentStep === 1,
      completed: currentStep > 1,
    },
    {
      label: "Review and save",
      active: currentStep === 2,
      completed: currentStep > 2,
    },
  ];

  const canNext =
    currentStep === 0 ? !(selectedCountries.length > 0 && selectedTypes.length > 0) : false;

  return (
    <form className="container_wapper" onSubmit={(e) => changeStep(currentStep + 1, e)}>
      <div className="steeper-wrapper">
        <Stepper stepItems={dataToStepper} />
      </div>

      <div className="container_wapper_data">{currentComponent}</div>
      {hasError && (
        <Alert id="error-alert" status="error" noDismiss data-testid="alert-error-message">
          <div>{errorMessage}</div>
        </Alert>
      )}

      <div className="container_wapper_actions avalara_divider">
        <button
          disabled={loader || currentStep === 0}
          onClick={() => {
            setHasError(false);
            changeStep(currentStep - 1);
          }}
          className="secondary"
          type="button"
          data-testid="button-back"
        >
          Back
        </button>
        <button
          disabled={canNext}
          className={loader ? "primary loading" : "primary"}
          type="submit"
          data-testid="button-next"
        >
          {isLastStep ? "Save" : "Next"}
        </button>
      </div>
    </form>
  );
};

export default Wapper;
