import { useEffect, useState, useMemo } from "react";
import { Alert, Pagination, Icon } from "@avalara/skylab-react";
import {
  PaginationType,
  deactivateTemplatesBySchemaId,
  getTemplatesByTenantId,
} from "../../services/schemaService";
import { MappingContext } from "../../context/mappingDataContext";
import { ISchema } from "../../domain/mappingTypes";
import LoadingMask from "../../ui/LoadingMask/LoadingMask";
import TemplateTableAvalara from "./TemplateAvalaraTable";
import SlideDownEffect from "../../ui/SlideDownEffect/SlideDownEffect";
import ConfirmDialog from "./ConfirmDialog";
import ModalEntrance from "../../ui/ModalEntrance/ModalEntrance";

export type TPagination = {
  startIndex: number;
  endIndex: number;
  currentPage: number;
  rowsPerPage: number;
  flag: string;
  sort: string;
  order: string;
};

const errorMessageFetch =
  "An error occurred when trying to get template list. Please check your internet and vpn conection.";
const errorMessageDeactivate = "An error occurred on delete";
const successMessageDeactivate = "Template deleted successfully";

const ConfirmMessageDeactivate: React.FunctionComponent<{ schemaName: string }> = (props) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <div style={{ marginLeft: "30px", marginRight: "20px" }}>
      <Icon name="alert-triangle-filled" />
    </div>
    <div style={{ fontSize: "18px", fontStyle: "italic", wordBreak: "break-all" }}>
      Are you sure you want to delete the template{" "}
      <span>
        <b>"{props.schemaName}"</b>
      </span>
      {""}?
    </div>
  </div>
);

const SchemasList = () => {
  const {
    dataComponent: { tenantId },
  } = MappingContext();
  const [templates, setTemplates] = useState<ISchema[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [schemaId, setSchemaId] = useState<string>("");
  const [schemaName, setSchemaName] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pagination, setPagination] = useState<TPagination>({
    startIndex: 0,
    endIndex: 0,
    currentPage: 1,
    rowsPerPage: 10,
    flag: "",
    sort: "",
    order: "",
  });

  const { rowsPerPage, startIndex, sort, order } = pagination;

  const fetchTemplates = useMemo(
    () => async () => {
      const paginationInfo: PaginationType = {
        take: rowsPerPage,
        skip: startIndex,
        sort: sort,
        order: order,
      };
      try {
        setIsLoading(true);
        const result = await getTemplatesByTenantId(tenantId, paginationInfo);
        setTemplates(result.values);
        setTotalRecords(result.count);
      } catch (error) {
        setError(true);
        setErrorMessage(errorMessageFetch);
        console.error("Error fetching templates:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [tenantId, rowsPerPage, startIndex, sort, order]
  );

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const deactivateHandler = async () => {
    try {
      setIsLoading(true);
      await deactivateTemplatesBySchemaId(schemaId);
      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
      fetchTemplates();
    } catch (error) {
      setError(true);
      setErrorMessage(errorMessageDeactivate);
      setIsLoading(false);
      console.error("Error deactivating templates", error);
    } finally {
      onDialogDismiss();
    }
  };

  const showConfirmHandler = (schemaId: string, schemaName: string) => {
    setShowDialog(true);
    setSchemaId(schemaId);
    setSchemaName(schemaName);
  };

  const onDialogDismiss = () => {
    setShowDialog(false);
    setSchemaId("");
    setSchemaName("");
  };

  return (
    <>
      <h2>Templates</h2>

      <SlideDownEffect showElement={error} id="ErrorMessage">
        <Alert status="error" onSDismiss={(e) => setError(false)} className="margin-bottom-md">
          <div>{errorMessage}</div>
        </Alert>
      </SlideDownEffect>

      <SlideDownEffect showElement={success} id="SuccessMessage">
        <Alert status="success" onSDismiss={(e) => setSuccess(false)} className="margin-bottom-md">
          <div>{successMessageDeactivate}</div>
        </Alert>
      </SlideDownEffect>

      <ModalEntrance id="confirm-dialog" showElement={showDialog} duration={0.2}>
        <ConfirmDialog
          title="Confirm"
          content={<ConfirmMessageDeactivate schemaName={schemaName} />}
          showDialog={showDialog}
          onConfirm={deactivateHandler}
          onCancel={onDialogDismiss}
        />
      </ModalEntrance>

      <div className="s-table-container" style={{ minHeight: "348px" }}>
        {isLoading && <LoadingMask topSpace="45px" />}
        <TemplateTableAvalara
          templateList={templates}
          onChangeSort={setPagination}
          onShowConfirm={showConfirmHandler}
        />
      </div>

      <Pagination
        className="margin-top-sm"
        disabled={isLoading}
        totalRecords={totalRecords}
        perPageLabel={"Elements per page"}
        onSPaginate={(e) =>
          setPagination((prevState) => {
            return { ...prevState, ...e.detail };
          })
        }
      />
    </>
  );
};

export default SchemasList;
