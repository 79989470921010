import api from "./api";

export const GetOperators = async () => {
  return api
    .get("/Conditions/operators")
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return error;
    });
};
