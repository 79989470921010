import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface IScaleEffect {
  children: React.ReactElement;
  showElement: boolean;
  id: string;
  initialWidth?: number;
}

const ScaleEffect: React.FunctionComponent<IScaleEffect> = (props) => {
  const _initialWidth = !props.initialWidth ? 0 : props.initialWidth;
  return (
    <AnimatePresence>
      {props.showElement && (
        <motion.div
          key={props.id}
          initial={{ opacity: 0, height: 0, width: _initialWidth }}
          animate={{ opacity: 1, height: "auto", width: "auto" }}
          exit={{ opacity: 0, height: 0, width: _initialWidth }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScaleEffect;
