import React from "react";
import { IAnswerMapping, ITransformation } from "../../domain/mappingTypes";
import { motion } from "framer-motion";

type Props = {
  transformation: ITransformation;
  item: IAnswerMapping;
  setTransformation: React.Dispatch<React.SetStateAction<ITransformation>>;
};

const ByValueComponent = (props: Props) => {
  const activeZIndex = props.transformation.replaceType === "byValue" ? 3 : -1;
  return (
    <motion.div
      style={{ position: "absolute", top: 0, left: 0, zIndex: activeZIndex }}
      initial={{ width: 165, opacity: 1 }}
      animate={{
        width: props.transformation.replaceType === "byValue" ? 165 : "auto",
        opacity: props.transformation.replaceType === "byValue" ? 1 : 0,
        height: 60,
      }}
      transition={{ duration: 0.3 }}
    >
      <div className="inline-block pad-right-md" style={{ zIndex: 2 }}>
        <label htmlFor={"input-replace-value-" + props.item.from.columnName} className="required">
          Value:
        </label>

        <input
          autoFocus={Boolean(!props.transformation.value)}
          data-testid={"input-replace-value-" + props.item.from.columnName}
          id={"input-replace-value-" + props.item.from.columnName}
          type="text"
          maxLength={100}
          required={props.item.hasTransformation && props.transformation.replaceType === "byValue"}
          value={props.transformation.value || ""}
          onChange={(e) =>
            props.setTransformation((i) => {
              return { ...i, value: e.target.value };
            })
          }
        />
      </div>
    </motion.div>
  );
};

export default ByValueComponent;
