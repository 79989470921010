import jsonData from "./data.json";

export function getFullCountryData() {
  let map = {} as any;

  jsonData.forEach((item) =>
    item.requiredFor.forEach((field) => {
      if (!map[field.country]) {
        map[field.country] = new Set();
      }
      field.fillingType.forEach((type) => map[field.country].add(type));
    })
  );

  let transformedData = Object.keys(map).map((country) => ({
    country: country,
    fillingType: Array.from(map[country]),
  }));

  return transformedData;
}

export function getCountries() {
  const countries = new Set();
  jsonData.forEach((item) => {
    item.requiredFor.forEach((requiredItem) => {
      countries.add(requiredItem.country);
    });
  });
  return Array.from(countries) as string[];
}

export function getFillingTypes() {
  const fillingTypes = new Set();

  jsonData.forEach((column) => {
    column.requiredFor.forEach((requiredFor) => {
      requiredFor.fillingType.forEach((fillingType) => {
        fillingTypes.add(fillingType);
      });
    });
  });

  return Array.from(fillingTypes) as string[];
}

export function getColumnNames() {
  const distinctColumnNames = new Set();

  jsonData.forEach((item) => {
    distinctColumnNames.add(item.columnName);
  });

  return Array.from(distinctColumnNames);
}

export function getColumnNamesByCountryAndType(countries: string[], types?: string[]) {
  const typeVatGetAll = types?.length && types[0] === "VAT" ? undefined : types;
  return jsonData.filter((obj) => {
    return obj.requiredFor.some((req) => {
      const countryMatch = countries.includes(req.country);
      const typeMatch = typeVatGetAll ? typeVatGetAll.some((type) => req.fillingType.includes(type)) : true;

      return countryMatch && typeMatch;
    });
  }) as any[];
}
