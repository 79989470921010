import api from "./api";
import { ISchemaProperties } from "../domain/mappingTypes";
import { TransformationToAdd } from "../tools/schemaConversionTools";

export type addSchemaType = {
  name: string;
  extension: string;
  jqString: string;
  forwardDestinyPath: string;
  forwardDestinyType: "Api" | "Queue" | "Bucket";
  forwardDestinyRegion: string;
  tenantId: string;
  CsvSchema: ISchemaProperties;
  transformations: TransformationToAdd[];
  outputSchemaName: string;
  snapshot: string;
};

export type schemaResultType = {
  foragentId: string;
  forwardDestinyId: string;
  inputSchemaId: string;
  outInputSchemaId: string;
  schemaConvertionId: string;
  schemaOutputId: string;
  error: string;
};

export type PaginationType = {
  take: number;
  skip: number;
  sort: string;
  order: string;
};

export const addSchemaWorkflow = async (params: addSchemaType): Promise<schemaResultType> => {
  return api
    .post("/Workflow", params)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      let firstErrorKey = Object.keys(error.response.data.errors)[0];
      if (firstErrorKey.includes("Transformations") || firstErrorKey.includes("Name")) {
        return {
          error: error.response.data.errors[firstErrorKey],
        };
      } else {
        return null;
      }
    });
};

export const getSchemasByTenantId = async (tenantId: string, search: string = "") => {
  const response = await api
    .get(`/Tenants/${tenantId}/Schemas?active=true&count=true&schemaName=${search}`)
    .then((result) => result.data);
  return response;
};

export const getTemplatesByTenantId = async (tenantId: string, pagination: PaginationType) => {
  const { take, skip, sort, order } = pagination;
  let url = `/Tenants/${tenantId}/Schemas?take=${take}&skip=${skip}&active=true&latest=true&count=true`;
  if (sort && order) url += `&sortColumnName=${sort}&sortOrder=${order}`;
  return await api.get(url).then((result) => result.data);
};

export const deactivateTemplatesBySchemaId = async (SchemaId: string) => {
  return await api.put(`/Schemas/${SchemaId}/Status`, { active: false });
};
