import { ITransformation } from "../../domain/mappingTypes";

type Props = {
  handleChangeFlipSignagetype: (e: React.ChangeEvent<HTMLInputElement>) => void;
  transformation: ITransformation;
};

const ByFlipSignageComponent = (props: Props) => {
  return (
    <fieldset aria-labelledby="label2" className="radios-flipsignage">
      <input
        id={`flipSignage-${props.transformation.id}`}
        data-testid={`radio-flipSignage-${props.transformation.id}`}
        type="radio"
        name={`flipSignage-${props.transformation.id}`}
        value="flipSignage"
        checked={props.transformation.flipSignageType === "flipSignage"}
        onChange={props.handleChangeFlipSignagetype}
      />
      <label htmlFor={`flipSignage-${props.transformation.id}`}>Flip signage</label>
      <input
        id={`alwaysPositive-${props.transformation.id}`}
        data-testid={`radio-alwaysPositive-${props.transformation.id}`}
        type="radio"
        name={`flipSignage-${props.transformation.id}`}
        value="alwaysPositive"
        checked={props.transformation.flipSignageType === "alwaysPositive"}
        onChange={props.handleChangeFlipSignagetype}
      />
      <label htmlFor={`alwaysPositive-${props.transformation.id}`}>Always positive</label>
      <input
        id={`alwaysNegative-${props.transformation.id}`}
        data-testid={`radio-alwaysNegative-${props.transformation.id}`}
        type="radio"
        name={`flipSignage-${props.transformation.id}`}
        value="alwaysNegative"
        checked={props.transformation.flipSignageType === "alwaysNegative"}
        onChange={props.handleChangeFlipSignagetype}
      />
      <label htmlFor={`alwaysNegative-${props.transformation.id}`}>Always negative</label>
    </fieldset>
  );
};

export default ByFlipSignageComponent;
