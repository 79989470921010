import './loadingMask.scss';

type TLoadingMask = { topSpace?: string; smallSpinner?: boolean };

const LoadingMask = (props: TLoadingMask) => {
  const topSpace = props.topSpace ? props.topSpace : '0px';
  const smallSpinner = props.smallSpinner ? 'small' : '';
  return (
    <div className="loading-mask" data-testid="loading-mask" style={{ top: topSpace }}>
      <div className={`spinner ${smallSpinner}`}></div>
    </div>
  );
};

export default LoadingMask;
