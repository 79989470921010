import React from "react";
import { IAnswerMapping, ITransformation } from "../../domain/mappingTypes";
import { motion } from "framer-motion";
import { SkylabSelectWrapper } from "../../ui/FixSelectRequired/FixSelectRequired";

type Props = {
  transformation: ITransformation;
  item: IAnswerMapping;
  onChangeSelectOperation: (field: string, value: string, listName: string) => void;
  onChangeOperation: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const operationsSelectValues = [
  { label: String.fromCharCode(0x002b), value: "+" },
  { label: String.fromCharCode(0x2212), value: "-" },
  { label: String.fromCharCode(0x00d7), value: "*" },
  { label: String.fromCharCode(0x00f7), value: "/" },
];

const ByOperationComponent = (props: Props) => {
  const isOperation = props.transformation.replaceType === "byOperation";
  const activeZIndex = isOperation ? 3 : -1;
  return (
    <motion.div
      style={{ position: "absolute", top: 0, left: 0, zIndex: activeZIndex }}
      initial={{ width: 403, opacity: 0 }}
      animate={{
        width: isOperation ? 403 : "auto",
        opacity: isOperation ? 1 : 0,
        height: 60,
      }}
      transition={{ duration: 0.3 }}
    >
      <div className={`${isOperation ? "inline-flex" : "invisible"}`} style={{ gap: "8px" }}>
        <div style={{ width: "170px" }}>
          <label
            htmlFor={`dropdown-replace1-column-name-${props.transformation.id}`}
            className="required"
          >
            Column:
          </label>
          <SkylabSelectWrapper
            inputId={`dropdown-replace1-column-name-${props.transformation.id}`}
            id={`dropdown-replace1-column-name-${props.transformation.id}`}
            multiple={false}
            showSelectionCount={false}
            required={props.item.hasTransformation && isOperation}
            data-testid={`dropdown-replace1-column-name-${props.item.from.columnName}`}
            value={props.transformation.value ?? ""}
            onSSelect={(e: any) =>
              props.onChangeSelectOperation("value", e.detail.item.value, "selectOptionList1")
            }
            optionsList={props.transformation.selectOptionList1}
          />
        </div>
        <div>
          <label
            htmlFor={"select-operation-value-" + props.item.from.columnName}
            className="required"
          >
            option
          </label>
          <select
            data-testid={"select-operation-value-" + props.item.from.columnName}
            id={"select-operation-value-" + props.item.from.columnName}
            value={props.transformation.replaceOperation}
            onChange={props.onChangeOperation}
            required
          >
            {operationsSelectValues.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className="pad-right-sm" style={{ width: "180px" }}>
          <label
            htmlFor={`dropdown-replace2-column-name-${props.transformation.id}`}
            className="required"
          >
            Column:
          </label>
          <SkylabSelectWrapper
            inputId={`dropdown-replace2-column-name-${props.transformation.id}`}
            id={`dropdown-replace2-column-name-${props.transformation.id}`}
            multiple={false}
            showSelectionCount={false}
            required={props.item.hasTransformation && isOperation}
            data-testid={`dropdown-replace2-column-name-${props.item.from.columnName}`}
            value={props.transformation.replaceValueComplement ?? ""}
            onSSelect={(e: any) =>
              props.onChangeSelectOperation(
                "replaceValueComplement",
                e.detail.item.value,
                "selectOptionList2"
              )
            }
            optionsList={props.transformation.selectOptionList2}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default ByOperationComponent;
