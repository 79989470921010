import React, { useState, useRef, ChangeEvent } from "react";
import { Select } from "@avalara/skylab-react";

const noop = () => {
  // no operation (do nothing real quick)
};

export const SkylabSelectWrapper = (props: any) => {
  return <FixRequiredSelect {...props} SelectComponent={Select} />;
};

interface FixRequiredSelectProps {
  value?: string;
  onChange: (value: string, actionMeta: any) => void;
  SelectComponent: React.ComponentType<any>;
  required: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  inputId: string;
  className?: string;
}

const FixRequiredSelect: React.FC<FixRequiredSelectProps> = (props) => {
  const { SelectComponent, required, className, inputId, ...restProps } = props;
  const [value, setValue] = useState(props.value ?? "");
  const selectRef = useRef<HTMLDivElement>(null);

  const enableRequired = !props.isDisabled;

  const onChange = (valueChanged: string, actionMeta: any) => {
    props.onChange(valueChanged, actionMeta);
    setValue(valueChanged);
  };

  const getValue = () => {
    if (props.value !== undefined) return props.value;
    return value || "";
  };

  const scrollUlToLi = (ul: HTMLUListElement, li: HTMLLIElement) => {
    if (!ul || !li) return;
    ul.scrollTo({ top: 0 });
    const ulRect = ul.getBoundingClientRect();
    const liRect = li.getBoundingClientRect();
    const top = liRect.top - ulRect.top - 7;

    ul.scrollTo({ top: top });
  };

  const getLiList = (ul: HTMLUListElement): Promise<NodeListOf<HTMLLIElement>> => {
    return new Promise((resolve) => setTimeout(() => resolve(ul.querySelectorAll("li"))));
  };

  const fixSelectedItemFocusWhenOpen = async (e: any) => {
    const componentId = `[inputid=${e.detail.selectInputId}]`;
    const componentRoot = document.querySelector(componentId);
    const ul = componentRoot!.querySelector("ul")!;

    let selectedChild = 1;
    const liList = await getLiList(ul);
    liList.forEach((li, index) => {
      li.className = "";
      const liValue = li.getAttribute("data-value");
      if (liValue === props.value) {
        li.className = "visually-focused";
        selectedChild = index + 1;
      }
    });
    const liSelectedSeletor = `li:nth-child(${selectedChild})`;
    const liSelected = ul.querySelector(liSelectedSeletor) as HTMLLIElement;
    scrollUlToLi(ul, liSelected);
  };

  return (
    <div ref={selectRef} className={className}>
      <SelectComponent
        {...restProps}
        inputId={inputId}
        onChange={onChange}
        onSOpen={fixSelectedItemFocusWhenOpen}
      />
      {enableRequired && (
        <input
          id={`${inputId}-hidden`}
          tabIndex={-1}
          aria-label="select column required"
          autoComplete="off"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            position: "absolute",
            top: "28px",
          }}
          value={getValue()}
          onChange={(e: ChangeEvent<HTMLInputElement>) => noop()}
          onFocus={() => {
            (selectRef.current?.firstChild?.firstChild as HTMLDivElement).focus();
          }}
          required={required}
        />
      )}
    </div>
  );
};

FixRequiredSelect.defaultProps = {
  onChange: noop,
};

export default FixRequiredSelect;
