import React, { useState, useEffect, useRef, useMemo } from "react";

type TextSlicerProps = {
  value: string;
  customWidth?: number;
};

const TextSlicer: React.FunctionComponent<TextSlicerProps> = (
  props: TextSlicerProps
) => {
  const { value, customWidth } = props;
  const [slicedText, setSlicedText] = useState("");
  const [title, setTitle] = useState("");
  const containerRef = useRef(null);

  const findParentContainerWidth = useMemo(() => {
    return () => {
      let parentContainerWidth = customWidth;
      if (!parentContainerWidth) {
        let parent = containerRef.current as unknown as HTMLElement;
        while (parent && !parentContainerWidth) {
          parentContainerWidth = parent.offsetWidth;
          parent = parent.parentNode as unknown as HTMLElement;
        }
      }
      return parentContainerWidth;
    };
  }, [customWidth]);

  const findMaxStringLength = (
    textToTest: string,
    maxWidth: number
  ): [number, boolean] => {
    let div = document.createElement("div");
    div.style.display = "inline-block";
    div.style.whiteSpace = "nowrap";
    div.style.position = "absolute";
    div.style.visibility = "hidden";
    document.body.appendChild(div);
    div.textContent = textToTest;

    if (div.offsetWidth <= maxWidth) {
      document.body.removeChild(div);
      return [textToTest.length, false];
    }
    div.textContent = "";
    let i = 0;
    while (div.offsetWidth <= maxWidth && i < textToTest.length) {
      i++;
      div.textContent = textToTest.slice(0, i);
    }
    document.body.removeChild(div);
    return [i - 3, true];
  };

  const buildSlicedText = useMemo(() => {
    return (maxLength: number, isBigger: boolean) => {
      const tailText = isBigger ? "..." : "";
      return value.slice(0, maxLength) + tailText;
    };
  }, [value]);

  const updateSlicedText = useMemo(() => {
    return () => {
      const parentContainerWidth = findParentContainerWidth() as number;
      const [maxLength, isBigger] = findMaxStringLength(
        value,
        parentContainerWidth
      );
      const newSlicedText = buildSlicedText(maxLength, isBigger);
      setSlicedText(newSlicedText);
      setTitle(value.length > maxLength ? value : "");
    };
  }, [value, buildSlicedText, findParentContainerWidth]);

  useEffect(() => {
    updateSlicedText();
    window.addEventListener("resize", updateSlicedText);

    return () => {
      window.removeEventListener("resize", updateSlicedText);
    };
  }, [value, customWidth, updateSlicedText]);

  return (
    <div title={title} ref={containerRef}>
      {slicedText}
    </div>
  );
};

export default TextSlicer;
