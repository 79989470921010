import React, { useEffect } from "react";

import {
  Navigate,
  Outlet,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { MappingContext } from "../context/mappingDataContext";

const ProtectedRoutes = () => {
  const { dataComponent, setData } = MappingContext();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("tenantId");
  const featureFlag = searchParams.get("featureFlag");
  const enableVatPlatformProcessing = searchParams.get("enableVatPlatformProcessing");

  const location = useLocation();
  const memoizedLocation = location.pathname + location.search;

  useEffect(() => {
    if (id) {
      setData((d) => {
        return {
          ...d,
          tenantId: id,
          featureFlag: featureFlag ?? "false",
          enableVatPlatformProcessing: enableVatPlatformProcessing ?? "false"
        };
      });
      localStorage.setItem("tenantId", id);
      navigate(memoizedLocation);
    }
  }, [featureFlag, enableVatPlatformProcessing, id, navigate, setData, memoizedLocation]);

  if (dataComponent.tenantId) return <Outlet />;

  return <Navigate to="/error" replace />;
};

export default ProtectedRoutes;
