import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Wapper from "../pages/Mapping/Wapper";
import ImportFiles from "../pages/ImportFiles/ImportFiles";
import ProtectedRoutes from "./ProtectedRoutes";
import Error from "../pages/Error";
import SetCountries from "../pages/Mapping/SetCountries";
import SchemasList from "../pages/SchemasList/SchemasList";

const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<ImportFiles />} />
          <Route path="/import-file" element={<ImportFiles />} />
          <Route path="/mapping" element={<Wapper />} />
          <Route path="/schemas" element={<SchemasList />} />
        </Route>

        <Route path="/test" element={<SetCountries />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesConfig;
