import { useEffect, useState } from "react";
import * as xlsx from "xlsx";

import { Alert, Select } from "@avalara/skylab-react";

import { getSchemasByTenantId } from "../../services/schemaService";
import { sendFile } from "../../services/fileService";

import FadeInEffect from "../../ui/FadeInEffect/FadeInEffect";
import FileUpload from "../../components/FileUpload/file-upload.component";
import XlsDialog from "../../components/XlsDialog/XlsDialog";
import CsvDialog from "../../components/CsvDialog/CsvDialog";

import { ISchema, IHeaders } from "../../domain/mappingTypes";
import { MappingContext } from "../../context/mappingDataContext";
import {
  getHeadersFromXLSX,
  getRangeFromXLSX,
  getSampleData,
  getTypeFromCell,
  generateXlsxErrorMessage,
  isXls,
} from "../../utils/spreadsheet";

import "./ImportFiles.scss";
import LoadingMask from "../../ui/LoadingMask/LoadingMask";
import SlideDownEffect from "../../ui/SlideDownEffect/SlideDownEffect";

type TSelectSchema = ISchema & {
  label: string;
  value: string;
  selected?: boolean;
  disabled?: boolean;
};

const responseSize = 100;
const AvalaraVATReportingGenericTemplateId = "avalara vat reporting generic template";
const AvalaraVATReportingGLTemplate = "avalara vat reporting gl template";
const VatDefaultIds = [AvalaraVATReportingGLTemplate, AvalaraVATReportingGenericTemplateId];
const schemasDefault = [
  {
    schemaId: "",
    schemaName: "",
    schemaExtension: "",
    label: "Select...",
    value: "",
  },
  {
    schemaId: AvalaraVATReportingGenericTemplateId,
    schemaName: "Avalara VAT Reporting Full Template",
    schemaExtension: "",
    label: "Avalara VAT Reporting Full Template",
    value: AvalaraVATReportingGenericTemplateId,
  },
  {
    schemaId: AvalaraVATReportingGLTemplate,
    schemaName: "Avalara VAT Reporting GL Template",
    schemaExtension: "",
    label: "Avalara VAT Reporting GL Template",
    value: AvalaraVATReportingGLTemplate,
  },
];

function ImportFiles() {
  const { setHeaders, setData, dataComponent } = MappingContext();

  const [savedTemplate, setSavedTemplate] = useState("none");
  const [isProcessingUpload, setIsProcessingUpload] = useState(false);
  const [isLoadingSchemas, setIsLoadingSchemas] = useState(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("Upload error.");
  const [xlsHasError, setXlsHasError] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [worksheetNames, setWorksheetNames] = useState<string[]>([]);
  const [csvHasError, setCsvHasError] = useState(false);
  const [internetConnectionError, setInternetConnectionError] = useState(false);
  const [totalSchemas, setTotalSchemas] = useState(0);
  const [isAsyncSelect, setIsAsyncSelect] = useState(false);
  const [searchSelectValue, setSearchSelectValue] = useState("");

  const [file, setFile] = useState(new File([], ""));
  const [showDialogCsv, setShowDialogCsv] = useState(false);
  const [showDialogXls, setShowDialogXls] = useState(false);
  const [showConfirmHeadersCsv, setShowConfirmHeadersCsv] = useState(false);
  const [showConfirmHeadersXls, setShowConfirmHeadersXls] = useState(false);
  const [allowedExtensions, setAllowedExtensions] = useState<string[]>([]);
  const [schemas, setSchemas] = useState([
    { ...schemasDefault[0], selected: true } as TSelectSchema,
  ]);
  const [fileSizeLimit, setFileSizeLimit] = useState(100);
  const [selectedSchema, setSelectedSchema] = useState({ schemaId: "" } as TSelectSchema);
  const [savedTemplateName, setSavedTemplateName] = useState(
    localStorage.getItem("saveSuccess") ?? ""
  );

  useEffect(() => {
    if (dataComponent.featureFlag === "true") {
      setSavedTemplate("yes");
    }
    getData(dataComponent.tenantId);
    // eslint-disable-next-line
  }, [dataComponent.tenantId, dataComponent.featureFlag]);

  useEffect(() => {
    localStorage.removeItem("saveSuccess");
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearchSchemas(searchSelectValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
    //eslint-disable-next-line
  }, [searchSelectValue]);

  const getData = async (tenantId: string, search?: string) => {
    if (search) setIsLoadingSearch(true);
    else setIsLoadingSchemas(true);
    try {
      const response = await getSchemasByTenantId(tenantId, search);
      const data = response.values;
      let selectOptionsSchema = data.map((item: any) => {
        item.label = item.schemaName;
        item.value = item.schemaId;
        item.selected = false;
        return item;
      });
      if (search) {
        selectOptionsSchema = [...schemas, ...selectOptionsSchema];
      } else {
        selectOptionsSchema = [...schemasDefault, ...selectOptionsSchema];
      }

      setSchemas(selectOptionsSchema);
      if (!search) setTotalSchemas(response.count);
    } catch (error) {
      setInternetConnectionError(true);
    } finally {
      if (search) setIsLoadingSearch(false);
      else setIsLoadingSchemas(false);
      setTimeout(() => {
        if (!isAsyncSelect) setIsAsyncSelect(true);
      }, 300);
    }
  };

  const hideAllMessages = () => {
    setUploadSuccess(false);
    setUploadError(false);
    setXlsHasError(false);
    setCsvHasError(false);
    setSavedTemplateName("");
    setUploadErrorMessage("Upload error.");
  };

  const handleOptionChange = (option: string) => {
    setSavedTemplate(option);
    hideAllMessages();
    handleSchemaChange({ item: { schemaId: "" } });
    setData((d) => ({ ...d, headerRowPosition: 1 }));

    const schemaId = option === "yes" ? dataComponent.schemaId || "" : "";
    updateValidExtensions(schemaId);

    const event = new Event("savedTemplateOptionChanged");
    window.dispatchEvent(event);
  };

  const readXls = (_file: File) => {
    const reader = new FileReader();
    const headerRowInit = dataComponent.headerRowPosition - 1;
    const headerObject: Partial<IHeaders>[] = [];

    reader.onload = function (e) {
      const data = e.target!.result;
      let workbook: any;

      try {
        workbook = xlsx.read(data, {
          type: "binary",
          cellNF: true,
          cellDates: true,
        });
      } catch {
        hideAllMessages();
        setUploadError(true);
        setIsProcessingUpload(false);
        return;
      }

      const firstSheetName = workbook.SheetNames[0];
      const firstSheet = workbook.Sheets[firstSheetName];
      const range = getRangeFromXLSX(firstSheet);

      const sheetData: string[][] = xlsx.utils.sheet_to_json(firstSheet, {
        header: 1,
        raw: false,
        range: range,
        defval: "",
      });
      const sheetDataTyped: any[][] = xlsx.utils.sheet_to_json(firstSheet, {
        header: 1,
        range: range,
        defval: "",
      });

      const headers = getHeadersFromXLSX(sheetData, headerRowInit);
      const sampleData: string[][] = sheetData.slice(headerRowInit + 1, 6);

      const hasOutOfMemory = firstSheet === "";
      const hasNoFoundHeaders = headers.length === 0;
      const hasDuplicatedHeaders = hasDuplicates(headers);
      const message = generateXlsxErrorMessage(
        hasOutOfMemory,
        hasNoFoundHeaders,
        hasDuplicatedHeaders
      );

      headers.forEach((header, index) => {
        const cell = sheetData[headerRowInit + 1]?.[index] || "";
        const cellTyped = sheetDataTyped[headerRowInit + 1]?.[index] || "";

        headerObject.push({
          name: header,
          type: getTypeFromCell(cell, cellTyped),
          data: getSampleData(sampleData, index),
        });
      });

      setWorksheetNames(workbook.SheetNames);
      setHeaders(headerObject as IHeaders[]);
      setIsProcessingUpload(false);
      hideAllMessages();

      if (workbook.SheetNames.length === 1) {
        setShowConfirmHeadersXls(true);
      }

      if (hasDuplicatedHeaders || headers.length === 0) {
        setXlsHasError(true);
        setUploadErrorMessage(message);
      }
      setTimeout(() => {
        setShowDialogXls(true);
      }, 300);
    };

    reader.readAsBinaryString(_file);
  };

  const readCsv = (_file: any) => {
    let reader = new FileReader();

    reader.onload = () => {
      let csvData = reader.result;
      let csvRecordsArray = (csvData as string).split(/\r\n|\n/);

      let foundHeaders = [];
      let dataCSV: string[][] = [];
      const headerRowPosition = dataComponent.headerRowPosition;
      const schemaSeparator =
        dataComponent.schemaSeparator === "\\t" ? "\t" : dataComponent.schemaSeparator;

      const headerRow = csvRecordsArray[headerRowPosition - 1];
      foundHeaders = headerRow ? csvRecordsArray[headerRowPosition - 1].split(schemaSeparator) : [];
      for (let index = 0; index < 5; index++) {
        const line = csvRecordsArray[headerRowPosition + index];
        if (line) dataCSV.push(line.split(schemaSeparator));
      }

      const headerList: Array<IHeaders> = [];
      foundHeaders.forEach((header, index) => {
        const data = dataCSV.map((line) => line[index]).filter((x) => x !== "");
        headerList.push({ name: header, data: data });
      });

      setHeaders(headerList);
      const hasDuplicated = hasDuplicates(foundHeaders);
      const isValidHeaderAndValueSize = checkDataCompleteness(foundHeaders, dataCSV);

      setIsProcessingUpload(false);
      hideAllMessages();
      const csvHasError = hasDuplicated || foundHeaders.length <= 1 || !isValidHeaderAndValueSize;
      if (csvHasError) {
        setCsvHasError(true);
        const message = generateCsvErrorMessage(
          hasDuplicated,
          foundHeaders.length,
          isValidHeaderAndValueSize
        );
        setUploadErrorMessage(message);
      }
      setShowConfirmHeadersCsv(true);
    };

    reader.readAsText(_file);
  };

  const generateCsvErrorMessage = (
    hasDuplicated: boolean,
    headersLength: number,
    isValidSize: boolean
  ) => {
    switch (true) {
      case headersLength <= 1:
        return "No headers found. Please check the header row number and delimiter.";
      case !isValidSize:
        return "Error, corrupt document as source file contains less/more data columns than headers columns";
      case hasDuplicated:
        return "Duplicate columns in header.";
      default:
        return "An unexpected error occurred.";
    }
  };

  const uploadSavedTemplate = async (files: any) => {
    const result = await sendFile(
      files[0],
      VatDefaultIds.includes(selectedSchema.schemaId)
        ? selectedSchema.schemaId
        : selectedSchema.schemaName,
      dataComponent.enableVatPlatformProcessing === "true"
    );

    if (!result.error) {
      setIsProcessingUpload(false);
      setUploadSuccess(true);
      setUploadError(false);
    } else {
      setIsProcessingUpload(false);
      setUploadError(true);
      setUploadSuccess(false);
      if (result?.status === 409) {
        setUploadErrorMessage("This file has already been uploaded.");
      }
    }
  };

  const configureNewTemplate = (files: any) => {
    try {
      setData((d) => {
        return { ...d, file: files[0] };
      });

      if (isXls(files[0])) {
        readXls(files[0]);
        setShowConfirmHeadersXls(false);
        setData((d) => {
          return { ...d, schemaSeparator: ";" };
        });
      } else {
        setUploadError(false);
        setTimeout(() => setShowDialogCsv(true), 300);
        setFile(files[0]);
      }
    } catch (error) {
      hideAllMessages();
      setUploadError(true);
      setIsProcessingUpload(false);
    }
  };

  const updateUploadedFiles = async (files: any) => {
    if (!files || files.length === 0) return;

    hideAllMessages();
    setIsProcessingUpload(true);
    setHeaders([]);

    if (savedTemplate === "yes") {
      uploadSavedTemplate(files);
    } else {
      configureNewTemplate(files);
    }
  };

  const handleSchemaChange = (selectedObject: any) => {
    const selectedSchemaObj = selectedObject.item;
    const newSchemaList = schemas.map((item) => {
      item.selected = item.schemaId === selectedSchemaObj.schemaId;
      return item;
    });

    setData((d) => {
      return { ...d, schemaId: selectedSchemaObj.schemaId };
    });

    setSchemas(newSchemaList);
    updateValidExtensions(selectedSchemaObj.schemaId);
    setSelectedSchema(selectedSchemaObj);
  };

  const updateValidExtensions = (schema: string) => {
    if (
      schema === AvalaraVATReportingGenericTemplateId ||
      schema === AvalaraVATReportingGLTemplate
    ) {
      setAllowedExtensions([
        "xml",
        "txt",
        "multitxt",
        "intratxt",
        "extratxt",
        "xls",
        "xlsx",
        "gentxt",
        "json",
      ]);
      setFileSizeLimit(150);
    } else {
      setAllowedExtensions(["txt", "xls", "xlsx", "csv"]);
      setFileSizeLimit(100);
    }
  };

  const hasDuplicates = (data: string[]) => {
    const uniqueSet = new Set(data.map((c) => c.toString().toUpperCase()));
    return uniqueSet.size !== data.length;
  };

  const checkDataCompleteness = (headers: string[], sampleData: string[][]) => {
    const headerSize = headers.length;
    return sampleData.every((dataRow) => dataRow.length === headerSize);
  };

  const handleSearchSchemas = (search: string) => {
    const shemasFiltered = schemas.filter((schema) =>
      schema.schemaName.toLowerCase().includes(search.toLowerCase().trim())
    );
    if (!shemasFiltered.length && totalSchemas > responseSize)
      getData(dataComponent.tenantId, search);
  };

  const closeXlsDialogHandler = () => {
    setShowDialogXls(false);
    setShowConfirmHeadersXls(false);
    setIsProcessingUpload(false);
  };

  const canUpload = (savedTemplate === "yes" && dataComponent.schemaId) || savedTemplate === "no";

  return (
    <>
      <SlideDownEffect showElement={internetConnectionError} id="error-conection">
        <Alert status="error" noDismiss>
          <div data-testid="user-message-upload-error">
            An unexpected error occurred, please check your internet conection
          </div>
        </Alert>
      </SlideDownEffect>

      <div className="import-files-component">
        <h1 className="title avalara_title">Import files</h1>
        <hr />

        <p className="sub-title">
          Please answer the following questions to help ensure we map your data and create your
          template correctly.
        </p>

        <fieldset className="radio-group">
          <p>Does this file match an Avalara template or existing data mapping?</p>
          <input
            type="radio"
            name="saved"
            value="yes"
            id="saved-yes"
            data-testid="radio-saved-yes"
            checked={savedTemplate === "yes"}
            onChange={() => {
              handleOptionChange("yes");
            }}
          />
          <label className="radio-label" htmlFor="saved-yes">
            Yes
          </label>

          <input
            type="radio"
            name="saved"
            value="no"
            id="saved-no"
            data-testid="radio-saved-no"
            checked={savedTemplate === "no"}
            disabled={dataComponent.featureFlag === "true"}
            onChange={() => handleOptionChange("no")}
          />
          <label className="radio-label" htmlFor="saved-no">
            No
          </label>
        </fieldset>

        {savedTemplate === "no" && (
          <>
            <p>Select header row:</p>
            <input
              id="header-row-position"
              className="template-select"
              type="number"
              aria-label="header-row-position"
              min={1}
              value={dataComponent.headerRowPosition || 1}
              onFocus={(e) => e.target.select()}
              onChange={(e) => {
                setData((d) => {
                  const value = e.target.value ? parseInt(e.target.value) : 1;
                  return { ...d, headerRowPosition: value };
                });
              }}
            />
          </>
        )}

        {savedTemplate === "yes" && (
          <>
            <p>Which template would you like to use?</p>
            <div style={{ display: "inline-block" }}>
              {isLoadingSchemas && <LoadingMask smallSpinner={true} />}
              <Select
                className="template-select"
                inputId="dropdown-template-name"
                data-testid="dropdown-template-name"
                multiple={false}
                async={isAsyncSelect}
                loading={isLoadingSearch}
                disabled={schemas === undefined}
                showSelectionCount={false}
                optionsList={schemas}
                onSInput={(e) => setSearchSelectValue(e.detail.inputValue)}
                onSSelect={(e) => handleSchemaChange(e.detail)}
              />
            </div>
          </>
        )}

        <div className="uploader-container">
          <FileUpload
            disabled={!canUpload}
            isProcessing={isProcessingUpload}
            updateFilesCb={updateUploadedFiles}
            allowedExtensions={allowedExtensions}
            fileSizeLimit={fileSizeLimit}
          />
        </div>

        {uploadError && (
          <div className="alert-fix-position">
            <Alert status="error" onSDismiss={(e) => setUploadError(false)}>
              <p data-testid="user-message-upload-error">{uploadErrorMessage}</p>
            </Alert>
          </div>
        )}

        {uploadSuccess && (
          <Alert
            className="alert-fix-position"
            status="success"
            onSDismiss={(e) => setUploadSuccess(false)}
          >
            <p data-testid="user-message-upload-success">File uploaded successfully.</p>
          </Alert>
        )}

        {savedTemplateName && (
          <Alert
            className="alert-fix-position"
            status="success"
            onSDismiss={(e) => setSavedTemplateName("")}
          >
            <p data-testid="user-message-schema-save-success">
              Template {savedTemplateName} created successfully.
            </p>
          </Alert>
        )}

        <hr style={{ marginTop: "60px" }} />
      </div>

      <FadeInEffect id="dialogId" showElement={showDialogCsv} duration={0.3}>
        <CsvDialog
          file={file}
          showDialog={showDialogCsv}
          showConfirmHeadersCsv={showConfirmHeadersCsv}
          csvHasError={csvHasError}
          uploadErrorMessage={uploadErrorMessage}
          readCsv={readCsv}
          setShowDialog={setShowDialogCsv}
          setIsProcessingUpload={setIsProcessingUpload}
          setShowConfirmHeadersCsv={setShowConfirmHeadersCsv}
        />
      </FadeInEffect>

      <FadeInEffect id="confirmHeaders" showElement={showDialogXls} duration={0.3}>
        <XlsDialog
          showDialog={showDialogXls}
          showConfirmHeadersXls={showConfirmHeadersXls}
          setShowConfirmHeadersXls={setShowConfirmHeadersXls}
          worksheetNames={worksheetNames}
          xlsHasError={xlsHasError}
          uploadErrorMessage={uploadErrorMessage}
          onCloseXlsDialog={closeXlsDialogHandler}
        />
      </FadeInEffect>
    </>
  );
}

export default ImportFiles;
