import React, { useRef } from "react";
import { IAnswerMapping, IHeaders, ITransformation } from "../../domain/mappingTypes";
import { AnimatePresence, motion } from "framer-motion";
import { SelectOption } from "@avalara/skylab-react";
import SkylabSelectOrInputText from "./SkylabSelectOrInputText";
import { variantsSlideDown } from ".";

type Props = {
  transformation: ITransformation;
  item: IAnswerMapping;
  headers: IHeaders[];
  selectColumnOption: SelectOption[];
  setTransformation: React.Dispatch<React.SetStateAction<ITransformation>>;
  onChangeSelectConcatenation: (value: string, listsIndex: number) => void;
};

const ByConcatenationComponent = (props: Props) => {
  const isConcatenation = props.transformation.replaceType === "byConcatenation";
  const activeZIndex = isConcatenation ? 3 : -1;
  const concatSelectValues = [...props.transformation.concatenation!.selectOptionList!];
  const concatValues = [...props.transformation.concatenation!.values!];
  const concatIsInput = [...props.transformation.concatenation!.isInput!];
  const inputColumnNameRef = useRef<HTMLInputElement>(null);

  const addColumn = () => {
    concatSelectValues.push(props.selectColumnOption);
    props.setTransformation((prev) => ({
      ...prev,
      concatenation: {
        selectOptionList: concatSelectValues,
        values: [...prev.concatenation!.values!, ""],
        isInput: [...prev.concatenation!.isInput!, false],
      },
    }));
  };

  const removeColumn = (index: number) => {
    if (concatSelectValues.length > 2) {
      concatSelectValues.splice(index, 1);
      concatValues.splice(index, 1);
      concatIsInput.splice(index, 1);
      props.setTransformation((prev) => ({
        ...prev,
        concatenation: {
          selectOptionList: concatSelectValues,
          values: concatValues,
          isInput: concatIsInput,
        },
      }));
    }
  };

  const onTrasnformSelectInput = (isInput: boolean, index: number | undefined) => {
    const _index = index ?? 0;

    concatSelectValues[_index] = props.selectColumnOption;
    concatValues[_index] = "";
    concatIsInput[_index] = isInput;

    props.setTransformation((prev) => ({
      ...prev,
      concatenation: {
        selectOptionList: concatSelectValues,
        values: concatValues,
        isInput: concatIsInput,
      },
    }));

    if (isInput) {
      setTimeout(() => {
        inputColumnNameRef.current?.focus();
      }, 100);
    }
  };

  const getConcatenationPreview = () => {
    if (concatValues.every((value) => value === "")) return "";
    const previewArray: string[] = [];
    concatValues.forEach((value, index) => {
      const isInput = concatIsInput[index];
      if (isInput) {
        previewArray.push(value);
      } else {
        const header = props.headers.find((header) => header.name === value);
        previewArray.push(header?.data?.[0] ?? " ");
      }
    });
    return previewArray.join("-");
  };

  return (
    <AnimatePresence initial={false}>
      <motion.div
        style={{ zIndex: activeZIndex }}
        initial={false}
        animate={{
          width: isConcatenation ? 165 : 0,
          opacity: isConcatenation ? 1 : 0,
          height: isConcatenation ? "auto" : 0,
        }}
        transition={{ duration: 0.3 }}
      >
        <AnimatePresence initial={false}>
          {concatSelectValues.map((_, index) => {
            const isLast = concatSelectValues.length - 1 === index;
            const lessThanFive = concatSelectValues.length < 5;
            return (
              <motion.div
                key={index}
                style={{ position: "relative" }}
                variants={variantsSlideDown}
                initial="collapsed"
                animate="open"
                exit="collapsed"
              >
                <div style={{ width: "190px", height: "65px" }}>
                  <label
                    htmlFor={`dropdown-concat-${index}-column-name-${props.transformation.id}`}
                    className="required"
                  >
                    Column:
                  </label>

                  <SkylabSelectOrInputText
                    id={props.transformation.id}
                    index={index}
                    item={props.item}
                    isOptional={!isConcatenation}
                    changeSelectToInput={concatIsInput[index]}
                    showSwitchButton={isConcatenation}
                    selectShemaOption={concatSelectValues[index]}
                    inputRef={inputColumnNameRef}
                    inputValue={concatValues[index] ?? ""}
                    selectValue={concatValues[index] ?? ""}
                    onChangeSelect={(value) => props.onChangeSelectConcatenation(value, index)}
                    onTrasnformSelectInput={onTrasnformSelectInput}
                    onBlurInputColumnName={() => {}}
                    onChangeInputColumnName={(value) =>
                      props.onChangeSelectConcatenation(value, index)
                    }
                  />
                </div>
                {index === 1 && concatSelectValues.length <= 2 && (
                  <div className="button-concat concat-first">
                    <button
                      title="Add Column"
                      type="button"
                      data-testid={`button-add-${index}-column-name-${props.transformation.id}`}
                      className="button-add-remove-column add-column"
                      onClick={addColumn}
                    >
                      +
                    </button>
                  </div>
                )}
                {index > 1 && (
                  <>
                    {isLast && lessThanFive && (
                      <div className="button-concat concat-second">
                        <button
                          title="Add Column"
                          type="button"
                          data-testid={`button-add-${index}-column-name-${props.transformation.id}`}
                          className="button-add-remove-column add-column"
                          onClick={addColumn}
                        >
                          +
                        </button>
                      </div>
                    )}
                    <div className={`button-concat concat-first`}>
                      <button
                        title="Delete Column"
                        type="button"
                        data-testid={`button-remove-${index}-column-name-${props.transformation.id}`}
                        className="button-add-remove-column remove-column"
                        onClick={() => removeColumn(index)}
                      >
                        <span className="icon-delete"></span>
                      </button>
                    </div>
                  </>
                )}
              </motion.div>
            );
          })}
        </AnimatePresence>
        <div style={{ display: "relative" }}>
          <div className="concat-preview-label">Preview:</div>
          <div>{getConcatenationPreview()}</div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ByConcatenationComponent;
