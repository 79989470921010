import axios from "axios";
import getConfig from "./config";

export const apiFile = axios.create({
  baseURL: getConfig()?.FILES_API,
});

export const vatPlatformApiFile = axios.create({
  baseURL: getConfig()?.VAT_PLATFORM_FILES_API,
});

export const sendFile = async (file: File, selectedSchemaName: string, useVatPlatform: boolean) => {
  const headers: { "content-type": string; "Content-Disposition": string } = {
    "content-type": "multipart/form-data",
    "Content-Disposition": "form-data",
  };
  const config = {
    headers,
    withCredentials: true,
  };

  const formData = new FormData();
  formData.append("file", file);

  const api = useVatPlatform ? vatPlatformApiFile : apiFile;

  return api
    .post(`/files?schemaName=${selectedSchemaName}`, formData, config)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      return { error: true, status: error.response?.status | 400 };
    });
};

export default apiFile;
