import React from "react";
import { motion } from "framer-motion";

interface ValidationErrorsProps {
  validationError: string[];
}

const listContainerVariants = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const ValidationErrors: React.FC<ValidationErrorsProps> = ({ validationError }) => {
  return (
    <motion.ul
      className="container"
      variants={listContainerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.li
        key="duplicateName"
        className={`${validationError.includes("duplicateName") ? "error" : ""} `}
        variants={itemVariants}
      >
        Must be unique
      </motion.li>
      <motion.li
        key="spaces"
        className={`${validationError.includes("spaces") ? "error" : ""} `}
        variants={itemVariants}
      >
        Must not contain spaces
      </motion.li>
      <motion.li
        key="specialCharacters"
        className={`${validationError.includes("specialCharacters") ? "error" : ""} `}
        variants={itemVariants}
      >
        Must not contain special characters
      </motion.li>
      <motion.li
        key="numberBeginning"
        className={`${validationError.includes("numberBeginning") ? "error" : ""} `}
        variants={itemVariants}
      >
        Must not contain a number at the beginning
      </motion.li>
    </motion.ul>
  );
};

export default ValidationErrors;
