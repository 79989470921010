import * as xlsx from "xlsx";

export const getHeadersFromXLSX = (
  sheetData: string[][],
  headerRowInit: number
): string[] => {
  if (sheetData.length && sheetData[headerRowInit]) {
    const isEmptyLine = sheetData[headerRowInit].every(
      (cellValue) => cellValue === ""
    );
    return isEmptyLine ? [] : sheetData[headerRowInit];
  }
  return [];
};

export const getRangeFromXLSX = (spreadSheet: any) => {
  if (!spreadSheet["!ref"]) return 0;
  const lastColumn = xlsx.utils.decode_range(spreadSheet["!ref"]).e.c;
  const totalRows = xlsx.utils.decode_range(spreadSheet["!ref"]).e.r + 1;
  return totalRows > 100 ? `A1:${xlsx.utils.encode_col(lastColumn)}100` : 0;
};

export function getSampleData(
  sampleData: any[][],
  cellIndex: number
): string[] {
  const arrayReturn: string[] = [];
  sampleData.forEach((row) => {
    arrayReturn.push(row[cellIndex]);
  });
  return arrayReturn;
}

export function getTypeFromCell(cell: string, cellTyped: any) {
  if (cellTyped instanceof Date) {
    return "DateTime";
  } else if (isIntegerString(cell)) {
    return "Integer";
  } else if (isFloatString(cell)) {
    return "Decimal";
  } else {
    return typeof cellTyped === "boolean" ? "Boolean" : "String";
  }
}

export function isXls(file: File) {
  if (
    file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
    file.type === "application/vnd.ms-excel"
  ) {
    return true;
  } else {
    return false;
  }
}

export const generateXlsxErrorMessage = (
  hasOutOfMemory: boolean,
  hasNoFoundHeaders: boolean,
  hasDuplicatedHeaders: boolean
) => {
  switch (true) {
    case hasOutOfMemory:
      return "The file could not be read possibly due to its size. Try shrinking this file and uploading it again.";
    case hasNoFoundHeaders:
      return "No headers found. Please check the sheet and header row number.";
    case hasDuplicatedHeaders:
      return "Duplicate columns in header.";
    default:
      return "An unexpected error occurred.";
  }
};

function isIntegerString(str: string) {
  return /^\d+$/.test(str);
}

function isFloatString(str: string) {
  return /^-?\d+(\.\d+)?$/.test(str);
}
