import React from "react";

import RoutesConfig from "./routes";

const App = () => {
  return (
    <div style={{ maxWidth: "940px", padding: "20px", marginLeft: "auto", marginRight: "auto" }}>
      <RoutesConfig />
    </div>
  );
};

export default App;

