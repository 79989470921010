import React, { useState } from "react";

export function useMappingStepper({ steps, save }: any) {
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsState, setStepsState] = useState(steps);
  let previousStep: number;

  function changeStep(i: number, e: any = null) {
    if (e) e.preventDefault();

    if (i < 0 || i >= steps.length) save();

    if (i < steps.length) setCurrentStep(i);

    previousStep = currentStep;
    configureSteps();
  }

  function configureSteps() {
    if (previousStep === 1) {
      const updateSteps = [...steps];
      const currentComponet = React.cloneElement(updateSteps[0], {
        previousStep,
      });
      updateSteps[0] = currentComponet;
      setStepsState(updateSteps);
    }
  }

  function validForm(e: any) {
    if (e) e.preventDefault();
  }

  return {
    currentStep,
    currentComponent: steps === undefined ? null : stepsState[currentStep],
    changeStep,
    isLastStep: currentStep + 1 === steps.length,
    isFirstStep: currentStep === 0,
    validForm,
  };
}
