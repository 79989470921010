import React, { useRef, useState, useEffect } from "react";
import "./file-upload.scss";
import { Alert, Loader } from "@avalara/skylab-react";

type IProps = {
  updateFilesCb: any;
  disabled: boolean;
  isProcessing: boolean;
  allowedExtensions: Array<string>;
  fileSizeLimit: number;
};

const convertNestedObjectToArray = (nestedObj: any) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const FileUpload = ({
  updateFilesCb,
  disabled,
  isProcessing,
  allowedExtensions,
  fileSizeLimit,
}: IProps) => {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [fileSizeLimitExceeded, setFileSizeLimitExceeded] = useState(false);
  const [fileTypeNotAllowed, setFileTypeNotAllowed] = useState(false);
  let [files, setFiles] = useState(Array<File>);

  useEffect(() => {
    window.addEventListener("savedTemplateOptionChanged", savedTemplateOptionChanged);

    return () => {
      window.removeEventListener("savedTemplateOptionChanged", savedTemplateOptionChanged);
    };
  }, []);

  const getAllowedExtensionsAsString = () => {
    let extensions = "";
    allowedExtensions.forEach((ext) => {
      extensions += `.${ext} `;
    });
    return extensions;
  };

  const savedTemplateOptionChanged = () => {
    setFileTypeNotAllowed(false);
    setFileSizeLimitExceeded(false);
  };

  const handleUploadBtnClick = () => {
    if (fileInputField.current) {
      fileInputField.current.click();
    }
  };

  const validateFile = (file: File) => {
    let extenstionIsValid = false;
    let sizeIsValid = false;
    let fileExtension = file.name.split(".").pop()?.toLocaleLowerCase();

    if (fileExtension && allowedExtensions.includes(fileExtension)) {
      setFileTypeNotAllowed(false);
      extenstionIsValid = true;
    } else {
      setFileTypeNotAllowed(true);
    }
    if (file.size <= fileSizeLimit * 1000 * 1048) {
      setFileSizeLimitExceeded(false);
      sizeIsValid = true;
    } else {
      setFileSizeLimitExceeded(true);
    }

    if (extenstionIsValid && sizeIsValid) {
      files.push(file);
    }
  };

  const addNewFiles = (newFiles: Array<File>) => {
    for (let file of newFiles) {
      validateFile(file);
    }
    if (files.length > 0) {
      return files;
    } else {
      return [];
    }
  };

  const callUpdateFilesCb = (files: any) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e: any) => {
    files = [];
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
    }
  };

  const formatAcceptedFileExtensions = () => {
    let acceptedFilesAsString = "";
    allowedExtensions.forEach((ext) => {
      acceptedFilesAsString = acceptedFilesAsString + `.${ext},`;
    });
    return acceptedFilesAsString;
  };

  return (
    <>
      <section className="FileUploadContainer">
        {isProcessing && (
          <p className="DragDropText">
            <Loader loading={true} className="small fix-loader-position" />
            <span>Processing...</span>
          </p>
        )}
        {!isProcessing && !disabled && <p className="DragDropText">Drag and drop file here</p>}
        {!isProcessing && disabled && (
          <p className="DragDropText">
            Upload disabled - Please answer the question above to continue
          </p>
        )}
        {isProcessing && <p className="FileLimitsText">This may take a while.</p>}

        {!isProcessing && !disabled && (
          <p className="FileLimitsText">
            File can either be {getAllowedExtensionsAsString()} and has a maximum size of{" "}
            {fileSizeLimit} MB.
          </p>
        )}
        {!isProcessing && (
          <>
            <button
              className="UploadFileBtn"
              type="button"
              disabled={disabled}
              data-testid="button-select-file"
              onClick={handleUploadBtnClick}
            >
              <span>Choose file</span>
            </button>
            <input
              className="FormField"
              data-testid="input-file-upload"
              accept={formatAcceptedFileExtensions()}
              aria-label="file-upload"
              disabled={disabled}
              type="file"
              ref={fileInputField}
              onChange={handleNewFileUpload}
              title=""
              value=""
            />
          </>
        )}
      </section>

      {fileSizeLimitExceeded && (
        <Alert
          className="alert-fix-position margin-top-lg"
          status="error"
          onSDismiss={() => setFileSizeLimitExceeded(false)}
        >
          <p>File size is bigger than {fileSizeLimit} MB.</p>
        </Alert>
      )}

      {fileTypeNotAllowed && (
        <Alert
          className="alert-fix-position margin-top-lg"
          status="error"
          onSDismiss={() => setFileTypeNotAllowed(false)}
        >
          <p>File type not allowed.</p>
        </Alert>
      )}
    </>
  );
};

export default FileUpload;
