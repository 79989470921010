import React from "react";

import { Alert, Col, Row } from "@avalara/skylab-react";
import FromToComponent from "../../../components/FromToComponent";
import { MappingContext } from "../../../context/mappingDataContext";

import "./styles.scss";

const FieldsMapping = () => {
  const { fromTo, headers } = MappingContext();

  return (
    <>
      <h1 className="avalara_title">Required and optional columns</h1>
      <Alert id="alertId" status="info" noDismiss>
        <div>We need these settings to process your transactions.</div>
      </Alert>

      <Row className="hide-on-sm">
        <Col span="6" className="pad-bottom-none">
          <h2>Avalara - Destination Fields</h2>
        </Col>
        <Col span="1" className="pad-bottom-none"></Col>
        <Col span="5" className="pad-bottom-none">
          <h2>Imported File - Source Fields</h2>
        </Col>
      </Row>

      <hr className="margin-top-none" />

      {fromTo.map((item, index) => {
        return (
          <div key={item.id}>
            <FromToComponent item={item} data={headers} />
            {index < fromTo.length - 1 && <hr />}
          </div>
        );
      })}
    </>
  );
};

export default FieldsMapping;
