import { ISchema } from "../../domain/mappingTypes";
import { formatDate } from "../../utils/string";
import TextSlicer from "../../ui/TextSlicer/TextSlicer";
import { HeaderSortable, Icon } from "@avalara/skylab-react";
import { TPagination } from "./SchemasList";

type TemplateTableAvalaraProps = {
  templateList: ISchema[];
  onChangeSort: React.Dispatch<React.SetStateAction<TPagination>>;
  onShowConfirm: (schemaId: string, schemaName: string) => void;
};

const TemplateTableAvalara: React.FunctionComponent<TemplateTableAvalaraProps> = (
  props: TemplateTableAvalaraProps
) => {
  const getSortValue = (ascending: string) => {
    const _ascending = String(ascending);
    if (_ascending === "true") {
      return "Asc";
    } else if (_ascending === "false") {
      return "Desc";
    } else {
      return "";
    }
  };
  const handleSort = (sortData: { id: string; ascending: string }) => {
    props.onChangeSort((previousState) => {
      return {
        ...previousState,
        sort: sortData.id,
        order: getSortValue(sortData.ascending),
      };
    });
  };
  return (
    <table>
      <thead style={{ height: "45px" }}>
        <tr>
          <th style={{ width: "50%" }}>
            <HeaderSortable
              id="schemaName"
              data-testid="schemaName-header"
              text="Schema name"
              noDefaultSort={true}
              onSSort={(e) => handleSort(e.detail)}
              onSUnsort={() => handleSort({ id: "", ascending: "" })}
            ></HeaderSortable>
          </th>
          <th>
            <HeaderSortable
              id="schemaInsertedDate"
              data-testid="schema-inserted-date"
              text="Creation date"
              noDefaultSort={true}
              onSSort={(e) => handleSort(e.detail)}
              onSUnsort={() => handleSort({ id: "", ascending: "" })}
            ></HeaderSortable>
          </th>
          <th style={{ width: "15%" }} className="text-center">
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {props.templateList.length === 0 && (
          <tr>
            <td colSpan={10}>
              <div className="no-data-found">
                <i>No data found</i>
              </div>
            </td>
          </tr>
        )}

        {props.templateList.map((item) => (
          <tr key={item.schemaId}>
            <td>
              <TextSlicer value={item.schemaName} />
            </td>
            <td style={{ paddingLeft: "25px" }}>{formatDate(item.schemaInsertedDate)}</td>
            <td className="text-center">
              <button
                className="ghost-blue icon"
                title="Delete"
                style={{ marginLeft: "0" }}
                onClick={() => props.onShowConfirm(item.schemaId, item.schemaName)}
              >
                <Icon name="trash" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TemplateTableAvalara;
