import React from "react";
import { motion } from "framer-motion";

import "./styles.scss";

interface RadioPillProps {
  id: string | number;
  pillOptions: { label: string; value: string; type: string }[];
  transformationType: string;
  selectedValue: string;
  onChangeReplaceType: (replaceType: string) => void;
}

const RadioPill: React.FC<RadioPillProps> = ({
  id,
  pillOptions,
  transformationType,
  selectedValue,
  onChangeReplaceType,
}) => {
  pillOptions = pillOptions.filter((option) =>
    ["default", transformationType].includes(option.type)
  );
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ type: "spring", stiffness: 260, damping: 20 }}
    >
      <div className="tabs-position">
        <div className="tabs-transformation-container">
          <ul className="tabs-transformation">
            {pillOptions.map((option) => (
              <li key={option.value}>
                {selectedValue === option.value && (
                  <motion.div
                    layoutId={`transformation-type-${id}`}
                    transition={{ duration: 0.2 }}
                    className="button-background"
                  />
                )}
                <button type="button" onClick={() => onChangeReplaceType(option.value)}>
                  <span className={`${selectedValue === option.value ? "selected" : ""}`}>
                    {option.label}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </motion.div>
  );
};

export default RadioPill;
