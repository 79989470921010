import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import uuid from "react-uuid";
import {
  IAnswerMapping,
  IData,
  IOperator,
  ITemplateProperties,
  IHeaders,
} from "../domain/mappingTypes";
import { GetOperators } from "../services/conditionService";

export type IProps = {
  headers: IHeaders[];
  setHeaders: React.Dispatch<React.SetStateAction<IHeaders[]>>;
  selectedCountries: string[];
  setSelectedCountries: React.Dispatch<React.SetStateAction<string[]>>;
  selectedTypes: string[];
  setSelectedTypes: React.Dispatch<React.SetStateAction<string[]>>;
  values: string[];
  setValues: React.Dispatch<React.SetStateAction<string[]>>;
  fromTo: IAnswerMapping[];
  setFromTo: React.Dispatch<React.SetStateAction<IAnswerMapping[]>>;
  fromData: ITemplateProperties[];
  setFromData: React.Dispatch<React.SetStateAction<ITemplateProperties[]>>;
  dataComponent: IData;
  setData: React.Dispatch<React.SetStateAction<IData>>;
  operators: IOperator[];
};

export const MappingDataContext = createContext({} as IProps);

export function CreateMappingDataProvider(extraData: any): ({ children }: any) => any {

  return function MappingDataProvider({ children }: any) {
    const [headers, setHeaders] = useState([] as IHeaders[]);
    const [selectedCountries, setSelectedCountries] = useState([] as string[]);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [values, setValues] = useState([] as string[]);
    const [fromTo, setFromTo] = useState([] as IAnswerMapping[]);
    const [fromData, setFromData] = useState([] as ITemplateProperties[]);
    const [data, setData] = useState({
      schemaSeparator: "",
      headerRowPosition: 1,
      ...extraData
    } as IData);
    const [operators, setOperators] = useState([] as IOperator[]);

    useEffect(() => {
      setFromTo(
        fromData.map((item) => {
          return {
            from: item,
            id: uuid(),
            isDate: item.dataType.toLocaleLowerCase() === "date",
            isOptionalColumn: true,
            hasTransformation: false,
          } as IAnswerMapping;
        })
      );
    }, [fromData]);

    useEffect(() => {
      const getData = async () => {
        const data = await GetOperators();
        setOperators(data);
      };

      getData();
    }, []);

    const contextValue = useMemo(() => {
      return {
        headers,
        setHeaders,
        selectedCountries,
        setSelectedCountries,
        selectedTypes,
        setSelectedTypes,
        values,
        setValues,
        fromTo,
        setFromTo,
        fromData,
        setFromData,
        dataComponent: data,
        setData,
        operators,
      };
    }, [
      headers,
      selectedCountries,
      selectedTypes,
      values,
      fromTo,
      fromData,
      data,
      operators,
    ]);

    return (
      <MappingDataContext.Provider value={contextValue}>
        {children}
      </MappingDataContext.Provider>
    );
  }

}

export const MappingDataProvider = CreateMappingDataProvider({});

export default MappingDataProvider;

export const MappingContext = () => useContext(MappingDataContext);
