import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

interface ISlideDownEffect {
  children: React.ReactElement;
  showElement: boolean;
  id: string;
  initialAnimations?: boolean;
}

const SlideDownEffect: React.FunctionComponent<ISlideDownEffect> = (props) => {
  const initialAnimations = props.initialAnimations ?? false;
  return (
    <AnimatePresence initial={initialAnimations}>
      {props.showElement && (
        <motion.div
          key={props.id}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.3 }}
        >
          {props.children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SlideDownEffect;
